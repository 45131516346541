import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/ManifestMemberColumn";
import { Formik, Form } from "formik";
import FormikControl from "../formik/FormikControl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaPlus, FaUserPlus, FaMinusCircle, FaEdit } from 'react-icons/fa';
import MainButton from "../buttons/MainButton";
import SmallButton from "../buttons/SmallButton";
import AddMembersModal from "../modals/AddMembersModal";
import * as Yup from 'yup';
import '../table/Table.css';
import './ManifestComponent.css';
import { useGetVehicleTypes } from '../../../application/hooks/queryHooks';
import services from "../../../ioc/services";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from '../../../application/store/actions/ui';
import { SpinnerCircular } from 'spinners-react';

const AddEditManifestComponent = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const activeState = location.pathname.split("/")[2] ?? "all";
    const isLoading = useSelector(state => state.ui.loading);
    const [editManifestData, setEditManifestData] = useState();
    const [manifestMembers, setManifestMembers] = useState([]);
    const [addedParticipantRegNo, setAddedParticipantRegNo] = useState("");
    const [vehicleTypes, setVehicleTypes] = useState([{ key: "--Select Vehicle Type--", value: '' },]);
    const [isModalActive, setIsModalActive] = useState(false);
    const [errorMessage, setErroMessage] = useState("");
    const [removedParticipant, setRemovedParticipant] = useState([]);
    const [newParticipant, setNewParticipant] = useState([]);
    const [manifestDetails, setManifestDetails] = useState({
        plateNumber: '',
        description: '',
        capacity: '',
        vehicleType: '',
    });

    const validationSchema = Yup.object({
        plateNumber: Yup.string().required("Plate Number is required"),
        description: Yup.string().required("Car Description is required"),
        capacity: Yup.string().required("Vehicle Capacity is required"),
        vehicleType: Yup.string().required("Select Vehicle Type"),
    });

    const onSubmit = (values) => {
        const addManifestObj = {
            capacity: values.capacity,
            vehicleType: parseInt(values.vehicleType),
            vehicleNumber: values.plateNumber,
            description: values.description,
            manifestOccupantsJalsaNo: manifestMembers.map(m => m.registrationNumber)
        };
        if (manifestMembers.length > 0) {
            dispatch(setLoading(true));
            if (activeState === "add") {
                services.api.userRequests.addManifest(addManifestObj).then(res => {
                    dispatch(setLoading(false));
                    if (res.data.data !== null) {
                        services.toast.success(res.data.messages[0]);
                        navigate("/manifest");
                    }
                    else {
                        services.toast.error(res.data.messages[0])
                    }
                }).catch(error => {
                    dispatch(setLoading(false));
                    services.toast.error(error.message)
                });
            }
            else {
                if (editManifestData !== undefined) {
                    const editedManifest = {
                        referenceNumber: params.referenceNumber,
                        capacity: values.capacity,
                        vehicleType: parseInt(values.vehicleType),
                        vehicleNumber: values.plateNumber,
                        description: values.description,
                        manifestAddedOccupantsJalsaNo: [...newParticipant],
                        manifestRemovedOccupantsJalsaNo: [...removedParticipant]

                    }
                    services.api.userRequests.updateManifest(editedManifest).then(res => {
                        dispatch(setLoading(false));
                        services.toast.success(res.data.messages[0]);
                        navigate("/manifest");
                    }).catch(error => {
                        dispatch(setLoading(false));
                        services.toast.error(error.message)
                    });
                }
            }
        }
        else {
            services.toast.error("Manifest must contain at least One(1) Participant");
        }

    };
    const addMemberToManifest = () => {
        if (addedParticipantRegNo.length > 0) {
            dispatch(setLoading(true));
            services.api.userRequests.getParticipantByRegNo(addedParticipantRegNo).then(res => {
                const isMemberExist = manifestMembers.some(m => m?.registrationNumber?.toLowerCase() === addedParticipantRegNo.toLowerCase());
                if (typeof (res) == 'object' && !isMemberExist) {
                    if(res.status === false)
                    {
                              setErroMessage(`Participant With Reg No:(${addedParticipantRegNo}) Doesn't Exist!`)
                              return;
                    }
                    activeState !== "add" ? setNewParticipant([...newParticipant, res.registrationNumber]) : console.log("");
                    setManifestMembers([...manifestMembers, res]);
                    setIsModalActive(false);
                    setAddedParticipantRegNo("");
                }
                else {
                    isMemberExist ? setErroMessage("Participant has already been added!")  : setErroMessage(`Participant With Reg No:(${addedParticipantRegNo}) Doesn't Exist!`) ;
                    
                }
                dispatch(setLoading(false));
            })

        }
    }

    const removeMemberFromManifest = (registrationNumber) => {
        activeState !== "add" ? setRemovedParticipant([...removedParticipant, registrationNumber]) : console.log("");
        const newManifestMembers = manifestMembers.filter(m => m.registrationNumber !== registrationNumber);
        setManifestMembers(newManifestMembers);
    }

    const columns = useMemo(() => COLUMNS, []);
    const data = manifestMembers;
    console.log(manifestMembers, "manifestMembers")
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });

    const { isLoading: isVehicleLoading, data: vehicleData } = useGetVehicleTypes();

    useEffect(() => {
        if (activeState === "edit") {
            let reference = params.referenceNumber.toLowerCase();
            services.api.userRequests.getManifestByReferenceNo(reference).then(res => {
                if (res.data !== null) setEditManifestData(res.data);
                else {
                    services.toast.error(res.messages[0]);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (editManifestData !== undefined && manifestMembers.length == 0) {
            setManifestDetails({
                plateNumber: editManifestData?.vehicleNumber?.toUpperCase(),
                description: editManifestData?.description?.toUpperCase(),
                capacity: editManifestData?.capacity,
                vehicleType: editManifestData?.vehicleType,
            });
            let manifetOcuupants = [];
            editManifestData?.manifestOccupants.forEach(m => {
                services.api.userRequests.getParticipantByRegNo(m.jalsaRegNo).then(res => {

                    manifetOcuupants.push(res);
                    setManifestMembers([...manifestMembers, ...manifetOcuupants]);
                }
                )
            })
        }
    }, [editManifestData]);

    useEffect(() => {
        if (!isVehicleLoading && vehicleTypes.length == 1 && vehicleData !== undefined) {
            setVehicleTypes([...vehicleTypes, ...vehicleData]);
        }
    }, [isVehicleLoading]);


    return (
        <div className="add-manifest-container ">
            {
                isModalActive &&
                <AddMembersModal setIsModalActive={setIsModalActive} userInput={addedParticipantRegNo} setUserInput={setAddedParticipantRegNo} addMemberToManifest={addMemberToManifest} errorMessage={errorMessage} setErrorMessage={setErroMessage} isLoading={isLoading} />
            }
            <div className="add-manifest-minicon">
                <div className="add-manifest-title">
                    <span className="opacity-[49%] tracking-[0.2em] font-bold md:my-[20px] text-[16px] md:text-[20px]">
                        {activeState === "add" ? "ADD" : "EDIT"} MANIFEST </span>
                </div>
                <div className="add-manifest-inputs">
                    <Formik
                        initialValues={manifestDetails}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={onSubmit}
                        validateOnChange={false}
                    >
                        {formik => (
                            <Form>
                                <div className="flex flex-col md:flex-row md:justify-between md:mb-[20px]">
                                    <div>
                                        <FormikControl
                                            control="input"
                                            placeholder="Enter Plate No."
                                            name="plateNumber"
                                            label="VEHICLE'S PLATE NUMBER"
                                        />
                                    </div>

                                    <div className="mt-[10px] md:mt-[0px]">
                                        <FormikControl
                                            control="input"
                                            placeholder="Enter Location"
                                            name="description"
                                            label="Location"
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-col md:flex-row md:justify-between  md:mb-[20px] ">
                                    <div className="mt-[10px] md:mt-[0px]">
                                        <FormikControl
                                            control="input"
                                            placeholder="Enter Vehicle's Capacity"
                                            name="capacity"
                                            type="number"
                                            label="vehicle's capacity"
                                            min = "1"
                                        />
                                    </div>

                                    <div className="mt-[10px] md:mt-[0px]">
                                        <FormikControl
                                            control="select"
                                            options={vehicleTypes}
                                            name="vehicleType"
                                            label="vehicle type"
                                        />
                                    </div>
                                </div>
                                <div className="add-manifest-button relative">
                                    <MainButton type="submit" className="fixed bottom-24 md:bottom-6 flex items-center" >
                                        <span className="flex items-center">
                                            {activeState === "add" ? <FaPlus className="mr-2" /> : <FaEdit className="mr-2" />}
                                            {activeState === "add" ? "ADD" : "EDIT"} MANIFEST
                                        </span>
                                        <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isLoading} />
                                    </MainButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="add-manifest-members">
                    <div className="flex  justify-end h-[37px]">
                        <SmallButton className="flex items-center " onClick={() => setIsModalActive(true)}>
                            <FaUserPlus className="mr-[10px]" />Add Participants
                        </SmallButton>
                    </div>
                    <div className="h-[85%] overflow-y-auto">
                        {
                            manifestMembers.length > 0 &&
                            <table  {...getTableProps()} className="table">
                                <thead className="thead">
                                    {
                                        headerGroups.map((headerGroup, index) => (
                                            <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">
                                                <th>NAME</th>
                                                {
                                                    headerGroup.headers.map((column, index) => (
                                                        <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }

                                </thead>
                                <tbody {...getTableBodyProps()} className="tbody">
                                    {
                                        rows.map((row, index) => {
                                            prepareRow(row)
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr {...row.getRowProps()} className="tr-body">
                                                        <td>{`${row.original.lastName} ${row.original.firstName}`}</td>
                                                        {
                                                            row.cells.map((cell, index) => {
                                                                return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            })
                                                        }
                                                        <td>
                                                            <FaMinusCircle className="text-[red] cursor-pointer static z-20" onClick={() => removeMemberFromManifest(row.original.registrationNumber)} />
                                                        </td>
                                                    </tr>
                                                </React.Fragment>

                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            manifestMembers.length === 0 &&
                            <div className="flex h-full items-center justify-center">
                                <span className="opacity-[49%] tracking-[0.1em] font-bold text-[12px] md:text-[14px]">NO PARTICIPANT ADDED</span>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddEditManifestComponent;
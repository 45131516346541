import react, { useEffect, useState } from "react";
import logo from "../../../application/assets/images/jalsalogo.png";
import { useGetPresentEvent } from "../../../application/hooks/queryHooks";
import qrcode from "../../../application/assets/images/qrcode.png";
import { QRCodeCanvas } from "qrcode.react";

const ManifestSlipComponent = react.forwardRef(({ details, id }, ref) => {
    const [event, setEvent] = useState('');
    const { data, isLoading } = useGetPresentEvent();
    useEffect(() => {
        if (data !== undefined) {
            setEvent(data.data);
        }
    }, [isLoading, data])
    const qrcode2 = (
        <QRCodeCanvas
          id="qrCode"
          value={details?.qrCode}
          size={100}
          bgColor={"white"}
          level={"H"}
        />
    );

    return (
        <div ref={ref} className={`flex flex-col h-[90%] w-full md:items-center overflow-y-auto justify-center`} id={id}>
            <div className="h-[320px] w-[500px] bg-white border-2 border-black">
                <div className="logo-div2">
                    <div className="logo2">
                        <img className="w-10 h-10" src={logo} alt="logo" />
                    </div>
                    <div className="logo-text2">
                        <p className="main-text">JALSA SALANA NIGERIA {new Date(event?.eventYear).getFullYear().toString()}</p>
                    </div>
                </div>

                <div className="h-[15%] tracking-[0.15rem] flex items-center justify-center">
                    <p className="font-bold">MANIFEST SLIP (<span className="slip-body">{details?.referenceNumber}</span>)</p>
                </div>

                <div style={{marginBottom : '-35px'}} className="manifest-details grid-col h-[45%] ">
                    <div>
                        <span className="slip-head">PLATE NO:</span>
                        <span className="slip-body">{details?.vehicleNumber}</span>
                    </div>
                    <div>
                        <span className="slip-head">FROM:</span>
                        <span className="slip-body">{details?.description}</span>
                    </div>
                    <div>
                        <span className="slip-head">VEHICLE TYPE:</span>
                        <span className="slip-body">{details?.vehicleTypeName}</span>
                    </div>
                    <div>
                        <span className="slip-head">TOTAL MEMBERS:</span>
                        <span className="slip-body">{details?.manifestOccupants.length}</span>
                    </div>
                </div>
                <div style={{marginLeft: '35%'}} className="h-[50%] w-[80%]">{qrcode2}</div>
                {/* <div className="h-[25%] px-4">
                    <img className="h-20 float-right" src={qrcode} alt="qrCode" />
                </div> */}
            </div>


        </div>
    );
});

export default ManifestSlipComponent;
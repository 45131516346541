import React, { useState } from "react";
import { InputLabel } from "../../view/components/inputs/MainInput";
import services from "../../ioc/services";
import { setLoggedInUserDetails } from "../../application/store/actions/user";
import { setLoading } from "../../application/store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerRoundOutlined } from "spinners-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Recaptcha from "react-recaptcha";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [membershipNo, setMembershipNo] = useState("");
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isAdminLogin, setIsAdminLogin] = useState(
    location.pathname === "/login/admin"
  );
  const [isTajneedLogin, setIsTajneedLogin] = useState(
    location.pathname === "/login/tajneed"
  );

  const isLoggingIn = useSelector((state) => state.ui.loading);
   //const [isVerified, setVerified] = useState(false);

  const navigateLogin = (pathname) => {
    navigate(pathname);
  };

  useEffect(() => {
    setUserName("");
    setUserPassword("");
    setMembershipNo("");
    setIsAdminLogin(location.pathname === "/login/admin");
    setIsTajneedLogin(location.pathname === "/login/tajneed");
  }, [location]);

  //Think twice Before touching this login method cause ee get as ee be oo
  //Tamper with it at your own risk
  const login = () => {
    const userDetails = { userName: isTajneedLogin ? userName : membershipNo };
    if (isAdminLogin || isTajneedLogin) userDetails.password = userPassword;

    if (
      (!isTajneedLogin && membershipNo.trim().length === 0) ||
      ((isAdminLogin || isTajneedLogin) && userPassword.trim().length === 0)
    ) {
      services.toast.error("Please Fill Necessary Fields");
    } else {
      dispatch(setLoading(true));
      if (!isTajneedLogin) {
        services.api.userRequests.login(userDetails).then((response) => {
          if (!response.status) {
            if (response.message !== undefined) {
              services.toast.error(response.message);
            } else {
              services.toast.error("An Error Occured!!");
            }
            dispatch(setLoading(false));
          } else {
            if (!isAdminLogin) services.toast.success(response.message);
            const userDetails = {
              userId: response.data.userName,
              authToken: response.data.authToken,
              userRoles: [],
            };
            if (isAdminLogin) {
              let isAuthorized =
                response.data.roles.some(
                  (r) => r.toLowerCase().indexOf("national") > -1
                ) ||
                response.data.roles.some(
                  (r) => r.toLowerCase().indexOf("circuit") > -1
                ) ||
                response.data.roles.some(
                  (r) => r.toLowerCase().indexOf("jamaat") > -1
                );

              if (!isAuthorized) {
                services.toast.error(
                  "You aren't authorized to login through this medium"
                );
                dispatch(setLoading(false));
                return;
              } else services.toast.success(response.message);
              userDetails.userRoles = response.data.roles;
            }
            dispatch(setLoggedInUserDetails(userDetails));
            dispatch(setLoading(false));
          }
        });
      } else if (isTajneedLogin) {
        services.api.userRequests.loginTajneed(userDetails).then((response) => {
          if (response?.status == true) {
            const userDetails = {
              userId: response.data.userName,
              authToken: response.data.authToken,
              userRoles: response.data.roles,
            };

            if (
              response.data.roles.some(
                (r) => r.toLowerCase().indexOf("admin") > -1
              ) ||
              response.data.roles.some(
                (r) => r.toLowerCase().indexOf("nazim volunteer") > -1
              )
            ) {
              dispatch(setLoggedInUserDetails(userDetails));
              services.toast.success(response.message);
            } else {
              services.toast.error(
                "You aren't authorized to login through this medium"
              );
            }
          } else {
            services.toast.error(response?.message);
          }
          dispatch(setLoading(false));
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
    // if (isVerified) {
    //   login();
    // } else {
    //   services.toast.error("Please verify that you are a human!");
    // }
  };

  return (
    <>
      <form>
        <div className="flex flex-col my-6 ">
          {!isTajneedLogin && (
            <>
              <InputLabel opacity="100%">MEMBERSHIP NO.</InputLabel>
              <input
                className="login-input"
                type="text"
                value={membershipNo}
                onChange={(e) => {
                  setMembershipNo(e.target.value.toUpperCase());
                }}
                placeholder="Enter Membership No."
              />
              {isAdminLogin && (
                <div className="mt-3">
                  <InputLabel opacity="100%">PASSWORD</InputLabel>
                  <input
                    className="login-input"
                    type="password"
                    value={userPassword}
                    onChange={(e) => {
                      setUserPassword(e.target.value);
                    }}
                    placeholder="Enter Password"
                  />
                </div>
              )}
            </>
          )}
          {isTajneedLogin && (
            <>
              <InputLabel opacity="100%">USERNAME</InputLabel>
              <input
                className="login-input"
                type="text"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                placeholder="Enter Username"
              />

              <div className="mt-3">
                <InputLabel opacity="100%">PASSWORD</InputLabel>
                <input
                  className="login-input"
                  type="password"
                  value={userPassword}
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                  placeholder="Enter Password"
                />
              </div>
            </>
          )}
        </div>
        {/* <div className="mt-3" id="cap">
          <Recaptcha
            //sitekey=" 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            sitekey= {process.env.REACT_APP_SITE_KEY}
            render="explicit"
            onloadCallback={() => {
              console.log("done loading!");
            }}
            theme="light"
            verifyCallback={(response) => {
              //setFieldValue("recaptcha", response);
              if (response) {
                setVerified(true);
              }
            }}
          />
        </div> */}
        <br></br>
        <button className={`login-btn `} onClick={(e) => handleSubmit(e)}>
          <p>LOGIN</p>{" "}
          <SpinnerRoundOutlined
            className="ml-5"
            color="white"
            secondaryColor="#EEF0F2"
            size={25}
            thickness={150}
            enabled={isLoggingIn}
          />
        </button>
        <div className="my-8"></div>
        <div className="flex mt-6 justify-between">
          <a
            className="inline-block align-baseline font-bold text-xl text-[#1a2035]-500 hover:text-[#1a2035]-800 cursor-pointer"
            onClick={() => navigate(isAdminLogin ? "/login" : "/login/admin")}
          >
            {isAdminLogin ? "Login as Member" : "Login as Admin"}
          </a>
          <a
            className="inline-block align-baseline font-bold text-xl text-[#1a2035]-500 hover:text-[#1a2035]-800 cursor-pointer"
            onClick={() => navigate("/login/tajneed")}
          >
            Tajneed Team
          </a>
        </div>
      </form>
    </>
  );
};

export default LoginForm;

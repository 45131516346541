import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthentication from "../../application/hooks/useAuthentication";
import DeleteGuestModal from "../components/modals/DeleteGuestModal";
import DisplayRegisteredGuests from "../components/register/DisplayRegisteredGuests";
import RegisterAsVolunteer from "../components/register/RegisterAsVolunteer";
import RegisterGuest from "../components/register/RegisterGuest";
import RegisterMember from "../components/register/RegisterMember";
import RegisterSelf from "../components/register/RegisterSelf";
import TopNavigationComponent from "../components/topnav/TopNavigationComponent";
import NavigationLayout from "../layout/NavigationLayout";
import './RegistrationContainer.css';

const RegistrationContainer = () => {
    
    useAuthentication();
    const navigate = useNavigate();
    const location = useLocation();
    const userAuthLevel = useSelector(state => state.user.userAuthLevel);
    const activeOption =  location.pathname.split("/")[2] ?? "self";
   
    const navigateOptions = (activeOpt) => {
        if(activeOpt === "self")
        {
            navigate(`/register`);
        }
        else{
            
            navigate(`/register/${activeOpt}`);
        }
    }

    const navOptions = [
        {
            state: "self",
            displayText: "self registration"
        },
        {
            state: "guest",
            displayText: "guest registration"
        },
    ]

    if(userAuthLevel === "jamaat")
    {
        navOptions.splice(1, 0 , {
            state: "jamaat",
            displayText: "jama'at"
        });
    }
    else if(userAuthLevel === "circuit" || userAuthLevel === "national")
    {
        navOptions.splice(1, 0 , {
            state: "circuit",
            displayText: "circuit"
        });
    }


    return (
        <NavigationLayout>
            <TopNavigationComponent navOptions={navOptions} activeOption={activeOption} setActiveOption={navigateOptions}/>
            <div className="w-full h-full py-5 md:h-[90%] overflow-y-auto bg-white  rounded-2xl p-5">
                {
                    (activeOption === "self" || activeOption === "cancel") && <RegisterSelf />
                }
                {
                    activeOption === "guest" && <RegisterGuest />
                }
                {
                    (activeOption == "guests" || activeOption == "deleteguest") && <DisplayRegisteredGuests />
                } 
                {
                    activeOption === "volunteer" && <RegisterAsVolunteer/>
                }
                {
                    (activeOption === "jamaat" || activeOption === "circuit")  && <RegisterMember/>
                }
            </div>
        </NavigationLayout>
    );
}

export default RegistrationContainer;
import React, { useMemo, useEffect, useState } from "react";
import { useTable, useGlobalFilter, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/ManifestColumn";
import '../table/Table.css';
import { FaEdit, FaUser, FaPlus, FaPrint } from 'react-icons/fa';
import SmallButton from "../buttons/SmallButton";
import MainButton from "../buttons/MainButton";
import { useNavigate } from "react-router-dom";
import PaginateComponent from "../table/PaginateComponent";
import { SpinnerDotted } from 'spinners-react';
import { useGetMemberManifestByMemberNo } from "../../../application/hooks/queryHooks";
import { useSelector } from "react-redux";
import theme from "../../../application/utils/Theme";

const DisplayManifestComponent = () => {
    const navigate = useNavigate();
    const columns = useMemo(() => COLUMNS, []);
    const user = useSelector(state => state.user);
    const isAuthorized = user.userAuthLevel == "national" || user.userAuthLevel == "circuit" || user.userAuthLevel == "jamaat";
    const { data: manifests, isLoading } = useGetMemberManifestByMemberNo(user.userId);
    const [data, setData] = useState([]);


    useEffect(() => {
        if (manifests?.length > 0) {
            setData(manifests);
        }
    }, [isLoading, manifests]);



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
    } = useTable({
        columns,
        data,
    },
        useGlobalFilter,
        usePagination,
        useRowSelect,

    )

    const { pageIndex, pageSize } = state;

    const editManifest = (referenceNumber) => {

        navigate(`edit/${referenceNumber.replace("/", "-").toLowerCase()}`);
    };

    const displayManifestDetails = (referenceNumber) => {
        navigate(`details/${referenceNumber.replace("/", "-").toLowerCase()}`);
    }

    const viewParticipantInManifest = (referenceNumber) => {
        navigate(`participants/${referenceNumber.replace("/", "-").toLowerCase()}`);
    }
    
    const printSlip = (referenceNumber) => {
        navigate(`print/${referenceNumber.replace("/", "-").toLowerCase()}`);
    }
    return (

        <div className="w-full items-center h-[85%] md:h-[95%] ">
            <span className="tracking-[0.2em] font-bold flex justify-center md:my-[20px] text-[16px] md:text-[20px] bg-white  rounded-2xl p-5">MANIFESTS</span>
            <div className="flex justify-end">
                <MainButton onClick={() => navigate("add")} className="mt-2">
                    <span className="flex items-center"><FaPlus className="mr-2" /> Add Manifest</span>
                </MainButton>
                {
                    isAuthorized &&
                    <>
                        <div className="mx-2"></div>
                        <MainButton className="mt-2 p-2" background="white" color={theme.color} onClick={() => navigate("reference")}>
                            <FaEdit />
                        </MainButton>
                    </>
                }
            </div>
            <div className="md:px-5 h-[85%]  overflow-auto md:overflow-x-hidden bg-white  rounded-2xl p-5">
                {
                    data?.length > 0 &&
                    <table  {...getTableProps()} className="table md:ml-[20px]">
                        <thead className="thead">
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">
                                        {
                                            headerGroup.headers.map((column, index) => (
                                                <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))
                                        }
                                        <th>ACTION</th>
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()} className="tbody">
                            {
                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={index} >
                                            <tr {...row.getRowProps()} className="tr-body hover:cursor-pointer hover:shadow-lg ">
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return <td className="text-lg font-[500]" key={index} {...cell.getCellProps()} onClick={() => displayManifestDetails(row.original.referenceNumber)}>{cell.render('Cell')}</td>
                                                    })
                                                }

                                                <td>
                                                    <div className="flex gap-x-2">
                                                        <div>
                                                            <SmallButton onClick={() => { editManifest(row.original.referenceNumber) }} >
                                                                <FaEdit onClick={() => { editManifest(row.original.referenceNumber) }}></FaEdit>
                                                            </SmallButton>

                                                        </div>

                                                        <div>
                                                            <SmallButton onClick={() => viewParticipantInManifest(row.original.referenceNumber)}>
                                                                <FaUser type="button" onClick={() => {
                                                                    viewParticipantInManifest(row.original.referenceNumber);
                                                                }} />
                                                            </SmallButton>
                                                        </div>

                                                        <div>
                                                            <SmallButton onClick={() => printSlip(row.original.referenceNumber)}>
                                                                <FaPrint type="button" onClick={() => {
                                                                    printSlip(row.original.referenceNumber);
                                                                }} />
                                                            </SmallButton>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>

                                    )
                                })
                            }
                        </tbody>
                    </table>
                }
                {
                    data?.length === 0 && !isLoading &&
                    <div className="flex  h-full items-center justify-center">
                        <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">NO MANIFEST ADDED</span>
                    </div>
                }
                {
                    isLoading &&
                    <div className=" h-full w-full flex items-center justify-center">
                        <SpinnerDotted size={40} thickness={150} />
                    </div>
                }

            </div>
            <PaginateComponent pageIndex={pageIndex} pageOptions={pageOptions} canNextPage={canNextPage} canPreviousPage={canPreviousPage} previousPage={previousPage} nextPage={nextPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div >

    );
}

export default DisplayManifestComponent;
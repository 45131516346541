import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MainButton from "../buttons/MainButton";
import FormikControl from "../formik/FormikControl";
import { useEffect, useState } from "react";
import services from "../../../ioc/services";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from 'spinners-react';
import DisabledButton from "../buttons/DisabledButton";
import { useGetAllDepartments } from "../../../application/hooks/queryHooks";

const AddVolunteer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { data, isLoading } = useGetAllDepartments();
    const [departmentOption, setDepartmentOption] = useState([
        { key: "--Choose Department--", value: '' },
    ])
    const location = useLocation();
    const activeState = location.pathname.split("/")[2] ?? "all";
    const [editVolunteerData, setEditVolunteerData] = useState();
    const [initialValues, setInitialValues] = useState({
        jalsaNo: params.jalsano !== undefined ? params.jalsano : "",
        phoneNumber: "",
        departmentId: ""
    });

    const validationSchema = Yup.object({
        jalsaNo: Yup.string().required("Jalsa number is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        departmentId: Yup.string().required("Select Department!"),
    });

    const displayInput = [
        {
            label: "jalsa registration no",
            name: "jalsaNo",
            control: "input",
            placeholder: "Enter volunteer reg no.",
            disabled: params.jalsano !== undefined
        },
        {
            label: "phone Number",
            name: "phoneNumber",
            control: "input",
            placeholder: "Enter Phone Number",
        },
        {
            label: "email Address",
            name: "email",
            control: "input",
            placeholder: "Enter Email Address",
        },
        {
            label: "Department",
            name: "departmentId",
            control: "select",
            placeholder: "select department",
            options: departmentOption
        }
    ]

    const [isAdding, setIsAdding] = useState(false);
    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber.startsWith("0")) {
            return phoneNumber = "+234" + phoneNumber.slice(1);
          }
          return phoneNumber;
    }
    const submit = (values) => {
        
        const volunteerDetail = {
            jalsaNo: values.jalsaNo,
            phoneNumber: formatPhoneNumber(values.phoneNumber) ,
            departmentId: values.departmentId,
            email: values.email,
        }
       


        setIsAdding(true);
        if (activeState === "add") {
            services.api.userRequests.addVolunteer(volunteerDetail).then(res => {
                if (res?.data?.succeeded) {
                    services.toast.success(res?.data?.messages[0]);
                    navigate("/volunteers");
                }
                else {
                    services.toast.error(res?.data?.messages[0])
                    setIsAdding(false);
                }
            })
        }
        else {
            services.api.userRequests.editVolunteer(volunteerDetail).then(res => {
                if(res?.succeeded)
                {
                    services.toast.success(res?.messages[0])
                    navigate("/volunteers");
                }
                else{
                    services.toast.error(res?.messages[0])
                    setIsAdding(false);
                }
            })
        }
    }

    useEffect(() => {
        if (activeState === "edit") {
            services.api.userRequests.getVolunteerByJalsaNo(params?.jalsano).then(res => {
                const volunteer = {
                    jalsaNo: params.jalsano !== undefined ? params.jalsano : "",
                    phoneNumber: res?.data?.phoneNumber,
                    departmentId: res?.data?.departmentId,
                }
                setInitialValues(volunteer);
            });
        }
    }, []);

    useEffect(() => {
        if (data !== undefined && departmentOption.length === 1) {
            setDepartmentOption([...departmentOption, ...data.map(d => ({ key: d.name, value: d.departmentId }))])
        }
    }, [isLoading])

    return (
        <div>
            <h2 className="flex justify-center items-center my-6 font-bold text-[20px]"> {activeState === "add" ? "ADD" : "EDIT"} VOLUNTEER</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                enableReinitialize
                onSubmit={submit}
            >
                {formik => (

                    <Form>
                        <div className="register-component grid-column" >
                            {displayInput.map((d, index) => (
                                <FormikControl
                                    key={index * 0.5}
                                    label={d.label}
                                    name={d.name}
                                    placeholder={d.placeholder}
                                    options={d?.options}
                                    control={d.control}
                                    disabled={d.disabled}
                                    type={d?.type}
                                />
                            ))}

                        </div>
                        <div className="register-btn">
                            {
                                !isAdding &&
                                <MainButton className="flex items-center" type="submit" > <span>{activeState === "add" ? "ADD" : "EDIT"} VOLUNTEER</span> </MainButton>
                            }
                            {
                                isAdding &&
                                <DisabledButton disabled={true} className="flex items-center"> <span>{activeState === "add" ? "ADD" : "EDIT"} VOLUNTEER</span> <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isAdding} /> </DisabledButton>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default AddVolunteer;


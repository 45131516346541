import React, { useMemo, useEffect, useState } from "react";
import { useTable, useGlobalFilter, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/UserColumn";
import '../table/Table.css';
import { FaEdit, FaPlus } from 'react-icons/fa';
import SmallButton from "../buttons/SmallButton";
import MainButton from "../buttons/MainButton";
import services from "../../../ioc/services";
import { useNavigate } from "react-router-dom";
import PaginateComponent from "../table/PaginateComponent";
import { SpinnerDotted } from 'spinners-react';
import LabelledInputField from "../inputs/LabelledInputField";
import { useGetAllUsers , useGetAllRoles} from "../../../application/hooks/queryHooks";

const ListUsersComponent = () => {
    const navigate = useNavigate();
    const columns = useMemo(() => COLUMNS, []);
    const [data, setData] = useState([]);
    const { data: userData, isLoading } = useGetAllUsers();
    const {data: roleData, isLoading: roleLoading} = useGetAllRoles();
    const [roleOption, setRoleOption] = useState([]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
    } = useTable({
        columns,
        data,
    },
        useGlobalFilter,
        usePagination,
        useRowSelect,
    )

    const { pageIndex, pageSize } = state;

    const [selectedRole, setSelectedRole] = useState();

    const handleRoleChange = (value) => {
        setSelectedRole(value);
        if(value.toLowerCase() === 'all'){
            setData(userData);
            return;
        }

        services.api.userRequests.getUsersByRoleName(value).then(res=>{
           setData(res);
        })
       
    }

    useEffect(() => {
        if (userData !== undefined) {
            setData(userData);
        }
    }, [isLoading, userData])

    useEffect(()=>{
        if (roleData !== undefined) {
            setRoleOption([{roleName: 'All'},...roleData]);
        }
    }, [roleData, roleLoading])


    return (
        <div className="w-full items-center h-[85%] md:h-[95%] ">
            <span className="tracking-[0.2em] font-bold flex justify-center md:my-[20px] text-[16px] md:text-[20px] bg-white  rounded-2xl p-5 bg-white  rounded-2xl p-5">USERS</span>
            <div className="flex items-center">
                <SmallButton onClick={() => navigate("addrole")} className="h-10">
                    <span className="flex items-center"><FaPlus className="mr-2" /> Add Role</span>
                </SmallButton>
                <div className="flex-1"></div>
                <div className="w-[25%] mr-2">
                    <LabelledInputField type="select" label="Role" id="Role" 
                    value={selectedRole} 
                    onChange={(e) => handleRoleChange(e.target.value)}
                    data={roleOption.map(role => (
                        {
                            value: role.roleName,
                            text: role.roleName
                        }
                    ))} />
                </div>
                <MainButton onClick={() => navigate("add")} className="h-10">
                    <span className="flex items-center"><FaPlus className="mr-2" /> Add User</span>
                </MainButton>
            </div>
            <div className="md:px-5 h-[70%] overflow-auto md:overflow-x-hidden bg-white  rounded-2xl p-5">
                {
                    data?.length > 0 &&
                    <table  {...getTableProps()} className="table md:ml-[20px]">
                        <thead className="thead">
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">
                                        <th>NAME</th>
                                        {
                                            headerGroup.headers.map((column, index) => (
                                                <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))
                                        }
                                        <th>ACTION</th>
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()} className="tbody">
                            {
                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={index} >
                                            <tr {...row.getRowProps()} className="tr-body ">
                                                <td>{`${row.original.lastName} ${row.original.firstName}`}</td>
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return <td key={index} {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                                    })
                                                }

                                                <td>
                                                    <div className="flex gap-x-2">
                                                        <SmallButton onClick={() => navigate(`edit/${row.original.userName}`)}>
                                                            <FaEdit onClick={() => navigate(`edit/${row.original.userName}`)}></FaEdit>
                                                        </SmallButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>

                                    )
                                })
                            }
                        </tbody>
                    </table>
                }
                {
                    data?.length === 0 && !isLoading &&
                    <div className="flex  h-full items-center justify-center">
                        <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">NO USER</span>
                    </div>
                }
                {
                    isLoading &&
                    <div className=" h-full w-full flex items-center justify-center">
                        <SpinnerDotted size={40} thickness={150} />
                    </div>
                }

            </div>
            <PaginateComponent pageIndex={pageIndex} pageOptions={pageOptions} canNextPage={canNextPage} canPreviousPage={canPreviousPage} previousPage={previousPage} nextPage={nextPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div >
    );
}

export default ListUsersComponent;

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import services from "../../../ioc/services";
import MainButton from "../buttons/MainButton";
import FormikControl from "../formik/FormikControl";
import '../register/RegisterComponent.css'
import { setLoading } from '../../../application/store/actions/ui';
import { SpinnerCircular } from 'spinners-react';
import { useState } from "react";
import NavigationLayout from "../../layout/NavigationLayout";
import useAuthentication from "../../../application/hooks/useAuthentication";
import useAuthorization from "../../../application/hooks/useAuthorization";

const CreateEvent = () => {
    useAuthentication();
    useAuthorization();

    const isRegistering = useSelector(state => state.ui.loading);
    const dispatch = useDispatch();

    const [event, setEvent] = useState({
    })


    const displayInput = [
        {
            label: "event Year",
            name: "eventYear",
            type: "text",
            placeholder: "Enter Event Year."
        },
        {
            label: "start Date",
            name: "startDate",
            type: "date",
        },
        {
            label: "end Date",
            name: "endDate",
            type: "date",
        },
        {
            label: "location",
            name: "location",
            type: "text",
            placeholder: "Enter Location"
        },
        {
            label: "theme",
            name: "theme",
            type: "text",
            placeholder: "Enter Theme"
        },
        {
            label: "Event Number",
            name: "eventNo",
            type: "number",
            placeholder: "Enter Event No."
        },
    ]



    const initialValues = {
        eventYear: '',
        startDate: '',
        endDate: '',
        location: '',
        theme: '',
        eventNo: '',
    }


    const validationSchema = Yup.object({
        eventYear: Yup.string().required("Event year is required"),
        startDate: Yup.string().required("Start Date is required"),
        endDate: Yup.string().required("End Date is required"),
        location: Yup.string().required("Location is required"),
        theme: Yup.string().required("Theme is required"),
        eventNo: Yup.number().required("Event Number is required"),
    });

    const createEvent = (values, onSubmitProps) => {

        const eventDetails = {
            eventYear: new Date(values.eventYear),
            startDate: new Date(values.startDate),
            endDate: new Date(values.endDate),
            location: values.location,
            theme: values.theme,
            eventNumber: values.eventNo,
            programmeOfProgramme: " ",
        }

        dispatch(setLoading(true));
        services.api.userRequests.createEvent(eventDetails).then(res => {
            if(res.data.status)
            {
                services.toast.success(res.data.message);
                onSubmitProps.resetForm();
            }
            else{
                services.toast.error(res.data.message);
            }
            dispatch(setLoading(false));
        })
    }
    return (
       <NavigationLayout>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createEvent}
            validateOnChange={false}
        >
            {formik => (

                <Form>
                     <span className="tracking-[0.2em] font-bold flex justify-center md:my-[20px] text-[16px] md:text-[20px] bg-white  rounded-2xl p-5 bg-white  rounded-2xl p-5">CREATE EVENT</span>
                    <div className="register-component grid-column" >
                        {displayInput.map((d, index) => (
                            <FormikControl
                                key={index * 0.5}
                                label={d.label}
                                name={d.name}
                                type={d.type}
                                placeholder={d.placeholder}
                                control="input"
                            />
                        ))}

                    </div>
                    <div className="register-btn">

                        <MainButton className="flex items-center" type="submit" > <span>CREATE EVENT</span> <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isRegistering} /> </MainButton>
                    </div>

                </Form>
            )}
        </Formik>
       </NavigationLayout>
    );
}
export default CreateEvent;
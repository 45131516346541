import axios from "axios";

// const BASEURL = "https://localhost:7230";
const BASEURL = "https://jarms.ahmadiyyanigeria.net";
const CARDAPIURL = "https://jarms.ahmadiyyanigeria.net";

export const login = async (userDetails) => {
  try {
    const res = await axios.post(`${BASEURL}/api/Auth/token`, userDetails);
    res.data.data.authToken = res.headers.token;
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const loginTajneed = async (userDetails) => {
  try {
    const res = await axios.post(`${BASEURL}/api/Auth/admin`, userDetails);
    res.data.data.authToken = res.headers.token;
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getMemberByMemberNo = async (memberNo) => {
  try {
    const res = await axios.get(`${BASEURL}/api/Member/members/${memberNo}`);
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getVehicleTypes = async () => {
  try {
    const res = await axios.get(`${BASEURL}/api/Manifest/GetVehicleTypes`);
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export const getAllVolunteersWithoutPagination = async () => {
  try {
    const res = await axios.get(`${BASEURL}/api/Volunteer/GetAllVolunteersWithNoPagination`);
    console.log(res);
    return res.data;
    
  } catch (error) {
    return error.response.data;
  }
}

export const registerMember = async (memberNo) => {
  const res = await axios.post(
    `${BASEURL}/Register?memberNo=${memberNo}`,
    {},
    setConfig()
  );

  return res;
};
export const scan = async (code) => {
  const res = await axios.post(
    `${BASEURL}/Manifest/ScanManifest?QRCode=${code}`,
    {},
    setConfig()
  );

  return res;
};

export const registerGuest = async (guestDetails) => {
  const res = await axios.post(`${BASEURL}/Guest`, guestDetails, setConfig());

  return res;
};

export const getMemberManifestByMemberNo = async (
  memberNo,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/api/Manifest/GetMemberManifests?memberNo=${memberNo}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getParticipantByRegNo = async (regNo) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetParticipantDetailsByRegNo?RegNo=${regNo}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getParticipantByMemberNo = async (memberNo) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetParticipantDetailsByMemberNumber?memberNo=${memberNo}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getManifestByReferenceNo = async (referenceNo) => {
  try {
    const res = await axios.get(
      `${BASEURL}/api/Manifest/GetManifestByReferenceNumber?referenceNumber=${referenceNo}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getNationalRegistrationStats = async (
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetNationalDashBoardInformation?eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getCircuitRegistrationStats = async (
  circuitId,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetCircuitDashBoardInformation?circuitId=${circuitId}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getJamaatRegistrationStats = async (
  jamaatId,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetJamaatDashBoardInformation?jamaatId=${jamaatId}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const addManifest = async (manifestDetails) => {
  const res = await axios.post(
    `${BASEURL}/api/Manifest/CreateManifest`,
    manifestDetails,
    setConfig()
  );
  return res;
};

export const updateManifest = async (manifestDetails) => {
  const res = await axios.put(
    `${BASEURL}/api/Manifest/UpdateManifest`,
    manifestDetails,
    setConfig()
  );
  return res;
};

export const getGuestParticipantsByCreatorNo = async (
  creatorNo,
  pageNumber = 1,
  pageSize = 10,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetGuestParticipantsByCreatorNumber?CreatorNumber=${creatorNo}&PageNumber=${pageNumber}&PageSize=${pageSize}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getMemberParticipantsByCreatorNo = async (
  creatorNo,
  pageNumber = 1,
  pageSize = 10,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetMemberParticipantsByCreatorNumber?CreatorNumber=${creatorNo}&PageNumber=${pageNumber}&PageSize=${pageSize}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getMemberParticipantsByCircuit = async (
  circuitId,
  pageNumber = 1,
  pageSize = 10,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetParticipantsByCircuit?circuitId=${circuitId}&PageNumber=${pageNumber}&PageSize=${pageSize}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getMemberParticipantsByJamaat = async (
  jamaatId,
  pageNumber = 1,
  pageSize = 10,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetParticipantsByJamaat?jamaatId=${jamaatId}&PageNumber=${pageNumber}&PageSize=${pageSize}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getGuestParticipantsByJamaat = async (
  jamaat,
  pageNumber = 1,
  pageSize = 10,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetAllGuestsInJamaat?jamaatName=${jamaat}&PageNumber=${pageNumber}&PageSize=${pageSize}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getGuestParticipantsByCircuit = async (
  circuit,
  pageNumber = 1,
  pageSize = 10,
  eventYear = new Date().getFullYear()
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/GetAllGuestsInCircuit?circuitName=${circuit}&PageNumber=${pageNumber}&PageSize=${pageSize}&eventYear=${eventYear}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getParticipantsByCreatorNo = async (
  creatorNo,
  pageNumber = 1,
  pageSize = 10
) => {
  const res = await axios.get(
    `${BASEURL}/GetParticipantsByCreatorNumber?CreatorNumber=${creatorNo}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    setConfig()
  );
  return res;
};

export const deleteParticipantByGuestJalsaNo = async (jalsaNo) => {
  try {
    const res = await axios.delete(`${BASEURL}/RemoveGuestByCreatorWithGuestJalsaNumber?jalsaNumber=${jalsaNo}`, setConfig());
    return res;
  }
  catch (err) {
    return err.response.data;
  }
};

export const createEvent = async (eventDetails) => {
  const res = await axios.post(`${BASEURL}/api/Event`, eventDetails);
  return res;
};

export const getUnRegisteredMembersByCircuitId = async (
  circuitId,
  pageNumber = 1,
  pageSize = 10
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/api/Member/members/UnRegisteredMembersByCircuitName?circuitId=${circuitId}&PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getUnRegisteredMembersByJamaatId = async (
  jamaatId,
  pageNumber = 1,
  pageSize = 10
) => {
  try {
    const res = await axios.get(
      `${BASEURL}/api/Member/members/UnRegisteredMembersByJamaatName?jamaatId=${jamaatId}&PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const registerBulkMembers = async (memberIds) => {
  let memberNos = "";
  memberIds.forEach((memberId, index) => {
    memberNos += `memberNos=${memberId}${index === memberIds.length - 1 ? "" : "&"
      }`;
  });
  const res = await axios.post(
    `${BASEURL}/BulkRegistration?${memberNos}`,
    {},
    setConfig()
  );

  return res.data;
};

export const getPresentEvent = async () => {
  try {
    const res = await axios.get(`${BASEURL}/api/Event/GetPresentEvent`);
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getGetParticipantTag = async (jalsaNo) => {
  try {
    const res = await axios.get(`${BASEURL}/ParticipantTag?jalsanumber=${jalsaNo}`);
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getAllJamaats = async () => {
  const res = await axios.get(`${CARDAPIURL}/jamaats`);

  return res.data;
};

export const getAllCircuits = async () => {
  const res = await axios.get(`${CARDAPIURL}/circuits`);

  return res.data;
};

export const getAllEvents = async () => {
  const res = await axios.get(`${BASEURL}/api/Event/GetAllEvents`);

  return res.data;
};

export const addUser = async (userDetails) => {
  try {
    const res = await axios.post(`${BASEURL}/api/User/create`, userDetails, setConfig());
    return res;
  }
  catch (err) {
    return err.response.data;
  }


};

export const addRole = async (roleDetails) => {
  try {
    const res = await axios.post(`${BASEURL}/api/User/addrole`, roleDetails, setConfig());

    return res;
  }
  catch (err) {
    return err.response.data;
  }
};


export const getAllUsers = async () => {
  const res = await axios.get(`${BASEURL}/api/User/GetUsers`);

  return res.data;
};

export const getAllRoles = async () => {
  const res = await axios.get(`${BASEURL}/api/User/GetRoles`);

  return res.data;
};

export const getUsersByRoleName = async (roleName) => {
  const res = await axios.get(`${BASEURL}/api/User/GetUsersByRole?roleName=${roleName}`);

  return res.data;
};

export const getUsersByUserName = async (userName) => {
  const res = await axios.get(`${BASEURL}/api/User/GetbyName?name=${userName}`);

  return res.data;
};


export const addDepartment = async (departmentDetails) => {
  try {
    const res = await axios.post(`${BASEURL}/api/Department/CreateDepartment`, departmentDetails, setConfig());

    return res;
  }
  catch (err) {
    return err.response.data;
  }
};

export const editDepartment = async (departmentDetails) => {
  try {
    const res = await axios.put(`${BASEURL}/api/Department/UpdateDepartment`, departmentDetails, setConfig());

    return res.data;
  }
  catch (err) {
    return err.response.data;
  }
};

export const getDepartmentById = async (departmentId) => {
  const res = await axios.get(`${BASEURL}/api/Department/GetDepartmentById?Id=${departmentId}`);

  return res.data;
};

export const getAllDepartments = async () => {
  const res = await axios.get(`${BASEURL}/api/Department/GetDepartments`);

  return res.data;
};
export const getAllCircuitsStats = async () => {
  const res = await axios.get(`${BASEURL}/GetCircuitStats?eventYear=2023`);

  return res.data;
};

export const deleteDepartment = async (departmentId) => {
  try {
    const res = await axios.delete(`${BASEURL}/api/Department/DeleteDepartment?Id=${departmentId}`, setConfig());

    return res;
  }
  catch (err) {
    return err.response.data;
  }
};

export const addVolunteer = async (volunteerDetails) => {
  try {
    const res = await axios.post(`${BASEURL}/api/Volunteer/CreateVolunteer`, volunteerDetails, setConfig());

    return res;
  }
  catch (err) {
    return err.response.data;
  }
};

export const getVolunteerByJalsaNo = async (jalsaNo) => {
  try {
    const res = await axios.get(`${BASEURL}/api/Volunteer/GetVolunteerByJalsaNo?jalsaNo=${jalsaNo}`);

    return res;
  }
  catch (err) {
    return err.response.data;
  }


};


export const editVolunteer = async (volunteerDetails) => {
  try {
    const res = await axios.put(`${BASEURL}/api/Volunteer/EditVolunteer`, volunteerDetails, setConfig());

    return res.data;
  }
  catch (err) {
    return err.response.data;
  }
};

export const cancelVolunteerService = async (VolunteerRegNo) => {
  try {
    const res = await axios.put(`${BASEURL}/api/Volunteer/CancelVolunteerService?VolunteerRegNo=${VolunteerRegNo}`, setConfig());
    return res;
  }
  catch (err) {
    return err.response.data;
  }
};

export const cancelRegistration = async (jalsaNo) => {
  try {
    const res = await axios.put(`${BASEURL}/CancelRegistration?jalsaNumber=${jalsaNo}`, setConfig());
    return res;
  }
  catch (err) {
    return err.response.data;
  }
};

export const getVolunteersByDepartment = async (
  departmentName,
  pageNumber = 1,
  pageSize = 10
) => {
  const res = await axios.get(`${BASEURL}/api/Volunteer/GetVolunteersByDepartment?departmentName=${departmentName}&PageNumber=${pageNumber}&PageSize=${pageSize}`);

  return res.data;
};

export const getAllVolunteers = async (
  pageNumber = 1,
  pageSize = 10
) => {
  const res = await axios.get(`${BASEURL}/api/Volunteer/GetAllVolunteers?PageNumber=${pageNumber}&PageSize=${pageSize}`);

  return res.data;
};

export const getVolunteers = async (
) => {
  const res = await axios.get(`${BASEURL}/api/Volunteer/GetAllVolunteersByAuxilliary?auxilliaryBody=Lajna`);

  return res.data;
};


export const registerMemberOnChandaPortal = async (userData) => {
  let data = new FormData();

  data.append(
    "__VIEWSTATE",
    "w+FY5U7CF3WGVEzqw3vSxIq8swZJ0bOUE2lET5cm1MoHNXjbUmtP25M5n4xsuEyJ6xjiwgnwVv4V4oxWNUgFZcHL1HqYFudmnh5+MBpPY2atHN2BIKp6EBsNwH1LyfhB3GQq9fXs/OTQDPImfw4qHklo6J7RyLVH93lRcA8xMWIKZP/ezWLiOVoN8rI4MNizVdZZD1NhQhHEjT+sjEjWzA5V8Q1GSDrSUAcdOPskq27epfSzFcQuKk/ozSIannIwJoLg8HjH37ae4bttG9uMX3LIc/FiMmwCASpHHQwGTUs="
  );
  data.append("__VIEWSTATEGENERATOR", "799CC77D");
  data.append(
    "__EVENTVALIDATION",
    "kfJR85mlEBAzcJwVETcQgbym2RMhj3aT05dBLBxIsGXDU5Lu2e7rFHoOWb/h8mSy3w81F1pF5cesEQ1uDvXz2jgh0LRtgknek6lbvcVfR2HKpwGyYR35RPrlLFC+kixG6PC1HLSRQ6ksZYcY733lByBE2/eiBQslQU0kuFRLyJKscobRcvXcS4K1alJpAjdSMNlFONbgyudiJuef8vJbRZaB8kQTMYqW3COP05/fJkf/vve0468Y75b7upAQXcjzIysXeY4YbT8aUnE87PiRHft7jwbhIQIX62qHtzMdUlI="
  );
  data.append("txtChandaNo", userData.chandaNo);
  data.append("txtSurname", userData.surname);
  data.append("txtFirstname", userData.firstName);
  data.append("txtEmail", userData.email);
  data.append("txtPhone", userData.phoneNo);
  data.append("txtPassword", "12345");
  data.append("txtConfirmPassword", "12345");
  data.append("agreeterms", "on");
  data.append("btnRegister", "Sign Up");

  await axios.post("http://portal.ahmadiyyanigeria.net/register.aspx", data);

  return true;
};

const setConfig = () => {
  const authToken = JSON.parse(localStorage.getItem("userDetails"))?.authToken;

  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      ContentType: "application/x-www-form-urlencoded",
    },
  };

  return config;
};

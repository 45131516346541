import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCircuitRegistrationStats, useGetJamaatRegistrationStats, useGetNationalRegistrationStats } from "../../application/hooks/queryHooks";
import { setLoading } from "../../application/store/actions/ui";
import StatsCard from "../components/card/StatsCard";
import { SpinnerDotted } from 'spinners-react';

const CardStatsContainer = ({ selectedJamaat, selectedCircuit, selectedYear, setIsViewParticipant, setParticipantData }) => {

    const dispatch = useDispatch();
    const [section, setSection] = useState("jamaat");
    const userAuthLevel = useSelector(state => state.user.userAuthLevel);
    const isLoading = useSelector(state => state.ui.loading);

    const [stats, setStats] = useState({});
    const [displayStats, setDisplayStats] = useState([]);


    const jamaatStatsQuery = useGetJamaatRegistrationStats(selectedJamaat, selectedYear);
    const circuitStatsQuery = useGetCircuitRegistrationStats(selectedCircuit, selectedYear);
    const nationalStatsQuery = useGetNationalRegistrationStats(selectedYear);
 


    useEffect(() => {
        if (jamaatStatsQuery.isLoading || circuitStatsQuery.isLoading || nationalStatsQuery.isLoading) {
            dispatch(setLoading(true, 'Fetching Data ...'))
            return null;
        }
        switch (section) {
            case "jamaat":
                setStats(jamaatStatsQuery?.data?.data);
                dispatch(setLoading(false));
                break;
            case "circuit":
                setStats(circuitStatsQuery?.data?.data);
                dispatch(setLoading(false));
                break;
            case "national":
                setStats(nationalStatsQuery?.data?.data);
                dispatch(setLoading(false));
                break;

        }

    }, [stats, jamaatStatsQuery, circuitStatsQuery, selectedCircuit, selectedJamaat, selectedYear, section]);


    useEffect(() => {
        setDisplayStats([
            {
                title: "Total Participant",
                description: "Total Registered Participant",
                withProgress: false,
                value: stats?.allRegisteredParticipants,
                fraction: 1,
                bgColor: "#f5effc",
                display : section == "national" ,
            },
            {
                title: "CheckedIn Participant",
                description: "Total Present Participant",
                value: stats?.allPresentParticipants,
                fraction: stats?.allPresentParticipants?.length / stats?.allRegisteredParticipants?.length,
                bgColor: "#f9f9ee",
                display : section == "national",
            },
            {
                title: "Total Members",
                description: "Total Registered Members",
                withProgress: false,
                value: stats?.allMembersRegistered,
                fraction: 1,
                bgColor: "#f7f0ea",
            },
            {
                title: "CheckedIn Members",
                description: "Total Present Members",
                value: stats?.allMembersPresent,
                fraction: stats?.allMembersPresent?.length / stats?.allMembersRegistered?.length,
                bgColor: "#effcef",
            },
            {
                title: "Total Guest",
                description: "Total Registered Guest",
                withProgress: false,
                value: stats?.allGuestsRegistered,
                fraction: 1,
                bgColor: "#f7f0ea",
                display : section == "national",
            },
            {
                title: "CheckedIn Guest",
                description: "Total Present Guest",
                value: stats?.allGuestsPresent,
                fraction: stats?.allGuestsPresent?.length / stats?.allGuestsRegistered?.length,
                bgColor: "#effcef",
                display : section == "national",
            },
            {
                title: "Total Ansarullah",
                description: "Total Registered Ansarullah",
                value: stats?.allAnsarullahRegistered,
                withProgress: false,
                fraction: 1,
                bgColor: "#f5effc",
            },
            {
                title: "CheckedIn Ansarullah",
                description: "Total Present Ansarullah",
                value: stats?.allAnsarullahPresent,
                fraction: stats?.allAnsarullahPresent?.length / stats?.allAnsarullahRegistered?.length,
                bgColor: "#f9f9ee",
            },
            {
                title: "Total Lajna",
                description: "Total Registered Lajna",
                withProgress: false,
                value: stats?.allLajnaRegistered,
                fraction: 1,
                bgColor: "#f5effc",
            },
            {
                title: "CheckedIn Lajna",
                description: "Total Present Lajna",
                value: stats?.allLajnaPresent,
                fraction: stats?.allLajnaPresent?.length / stats?.allLajnaRegistered?.length,
                bgColor: "#f9f9ee",
            },
            {
                title: "Total Khuddam",
                description: "Total Registered Khuddam",
                withProgress: false,
                value: stats?.allKhuddamRegistered,
                fraction: 1,
                bgColor: "#f7f0ea",
            },
            {
                title: "CheckedIn Khuddam",
                description: "Total Present Khuddam",
                value: stats?.allKhuddamPresent,
                fraction: stats?.allKhuddamPresent?.length / stats?.allKhuddamRegistered?.length,
                bgColor: "#f5effc",
            },
            {
                title: "Total Nasrat",
                description: "Total Registered Nasrat",
                withProgress: false,
                value: stats?.allNasratRegistered,
                fraction: 1,
                bgColor: "#f9f9ee",
            },
            {
                title: "CheckedIn Nasrat",
                description: "Total Present Nasrat",
                value: stats?.allNasratPresent,
                fraction: stats?.allNasratPresent?.length / stats?.allNasratRegistered?.length,
                bgColor: "#f7f0ea",
            },
            {
                title: "Total Atfal",
                description: "Total Registered Atfal",
                withProgress: false,
                value: stats?.allAtfalRegistered,
                fraction: 1,
                bgColor: "#f5effc",
            },
            {
                title: "CheckedIn Atfal",
                description: "Total Present Atfal",
                value: stats?.allAtfalPresent,
                fraction: stats?.allAtfalPresent?.length / stats?.allAtfalRegistered?.length,
                bgColor: "#f9f9ee",
            },

        ]);

    }, [stats])


    return (
        <div className="h-[70vh]">

            <div className=" my-4 flex flex-col md:flex-row  justify-between items-center">
                <h1 className="font-bold uppercase">Jalsa Statistics For {section.toUpperCase()}</h1>
                <div className=" border border-[#1a2035]-600 flex gap-4 rounded-md overflow-hidden">
                    <span onClick={() => setSection('jamaat')}
                        className={`p-2 px-8 cursor-pointer  ${section === 'jamaat' ? 'bg-[#1a2035]-600 text-white' : ''}`}>Jamaat</span>

                    {(userAuthLevel === 'circuit' || userAuthLevel === 'national' || userAuthLevel === 'admin') &&

                        <span onClick={() => setSection('circuit')}
                            className={`p-2 px-8 cursor-pointer  ${section === 'circuit' ? 'bg-[#1a2035]-600 text-white' : ''}`}>Circuit</span>
                    }

                    {(userAuthLevel === 'national' || userAuthLevel === 'admin') &&
                        <span onClick={() => setSection('national')}
                            className={`p-2 px-8 cursor-pointer  ${section === 'national' ? 'bg-[#1a2035]-600 text-white' : ''}`}>All</span>

                    }

                </div>
            </div>

            {
                !isLoading &&
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
                    {
                        displayStats.map(d => (
                            <StatsCard
                                title={d.title}
                                description={d.description}
                                value={d.value?.length}
                                fraction={d.fraction}
                                withProgress={d.withProgress}
                                display={d.display}
                                bgColor={d.bgColor} 
                                onClick={()=>{
                                    setParticipantData({title:d.title, data: d.value});
                                    setIsViewParticipant(true);
                                }}/>
                        )
                        )
                    }
                </div>
            }
            {
                isLoading &&
                <div className=" h-full w-full flex items-center justify-center">
                    <SpinnerDotted size={40} thickness={150} />
                </div>
            }
        </div>
    )

}

export default CardStatsContainer;
import { Formik, Form } from "formik";
import React, { useState } from "react";
import * as Yup from 'yup';
import services from "../../../ioc/services";
import MainButton from "../buttons/MainButton";
import FormikControl from "../formik/FormikControl";
import './RegisterComponent.css';
import { SpinnerCircular } from 'spinners-react';
import DisabledButton from "../buttons/DisabledButton";
import { useSelector } from "react-redux";
import { useGetGuestParticipantsByCreatorNo } from "../../../application/hooks/queryHooks";
import {useNavigate} from "react-router"
import { CheckBox } from "../table/CheckBox";

const RegisterGuest = () => {
    const userId = useSelector(state => state.user.userId);
    const [isRegistering, setRegistering] = useState(false);
    const registeredGuestDetails = useGetGuestParticipantsByCreatorNo(userId);
    const navigate = useNavigate();

    const genderOption = [
        { key: "--Choose Gender--", value: '' },
        { key: "Female", value: "female" },
        { key: "Male", value: "male" },
    ]

    const initialValues = {
        firstname: '',
        lastname: '',
        middlename: '',
        emailAddress: '',
        phoneNumber: '',
        address: '',
        gender: '',
        title: '',
    }

    const validationSchema = Yup.object({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        // emailAddress: Yup.string().email('Must be a valid email').required("E-mail is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        address: Yup.string().required("Address is required"),
        gender: Yup.string().required("Select Gender!"),
    });

    const displayInput = [
        {
            label: "last name",
            name: "lastname",
            control: "input",
            placeholder: "Enter last name",
        },
        {
            label: "first Name",
            name: "firstname",
            control: "input",
            placeholder: "Enter first name",
        },
        {
            label: "middle Name",
            name: "middlename",
            control: "input",
            placeholder: "Enter middle name",
        },
        {
            label: "E-MAIL ADDRESS",
            name: "emailAddress",
            control: "input",
            placeholder: "Enter E-mail",
        },
        {
            label: "phone Number",
            name: "phoneNumber",
            control: "input",
            placeholder: "Input Phone No.",
        },
        {
            label: "gender",
            name: "gender",
            control: "select",
            options: genderOption,
        },
        {
            label: "title",
            name: "title",
            control: "input",
            placeholder: "Enter title"
        },
        {
            label: "address",
            name: "address",
            control: "input",
            placeholder: "Enter address",
        },

    ]

    const registerGuest = (values, onSubmitProps) => {
        let ahmadiCheck = document.querySelector("#check-if-ahmadi")
        if (ahmadiCheck.checked) {
            services.toast.error("Meet your jamaat or circuit president to create an account for him/her. ");
            return;
        }
        const guestDetails = {
            participantTypeName: 'guest',
            lastName: values.lastname,
            firstName: values.firstname,
            middleName: values.middlename,
            phoneNumber: values.phoneNumber,
            email: values.emailAddress,
            gender: values.gender,
            address: values.address,
            photo: null
        }
        setRegistering(true);
        services.api.userRequests.registerGuest(guestDetails).then(res => {
           
            if (!res.data.succeeded) {
                services.toast.error(res.data.messages[0]);
            } else {
                services.toast.success(res.data.messages[0]);
                onSubmitProps.resetForm();
            }
            setRegistering(false);
        })
    }
    return (
        <>
            <div className="view-guest-btn">
                {/* <MainButton className="flex items-center" onClick={() => {navigate(`/register/guests`)}}><span>VIEW GUESTS</span></MainButton> */}
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={registerGuest}
                validateOnChange={false}
            >
                {formik => (

                    <Form>
                        
                        <div className="register-component grid-column" >
                            <div>
                            <input type="checkbox" id="check-if-ahmadi" />
                        <label  style={{"color":"black", "fontWeight":"bolder", "fontSize":"25px"}}>Is the guest an Ahmadi?</label>
                        <FormikControl
                                    key={90}
                                    label={"Enter the membership  number"}
                                    name={"membershipNumber"}
                                    placeholder={"Membership Number"}
                                    options={""}
                                    control={"input"}
                                />
                        <p style={{"color":"red", "fontWeight":"bolder"}}>"And do not mix the truth <br/> with falsehood or conceal  <br/>the truth while you know [it]."  <br/>Surah Al-Baqarah (2:43):</p>
                            </div>                       
                            {displayInput.map((d, index) => (
                                <FormikControl
                                    key={index * 0.5}
                                    label={d.label}
                                    name={d.name}
                                    placeholder={d.placeholder}
                                    options={d?.options}
                                    control={d.control}
                                />
                            ))}

                        </div>
                        <div className="register-btn">
                            {
                                !isRegistering &&
                                <MainButton className="flex items-center" type="submit" > <span>REGISTER</span> </MainButton>
                            }
                            {
                                isRegistering &&
                                <DisabledButton disabled={true} className="flex items-center"> <span>REGISTER</span> <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isRegistering} /> </DisabledButton>
                            }
                        </div>
                    </Form>

                )}
            </Formik>
        </>
    );
}

export default RegisterGuest;
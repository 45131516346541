const LabelledInputField = ({ label, id, placeholder = "", type = "text", data = [], value='', onChange=null, disabled=false }) => {

    const classes = "bg-transparent border border-gray-400 rounded-md p-2 outline-[#1a2035]-400 w-full";
    return (
        <div className="flex flex-col mb-6 gap-y-2 w-full">
            <label htmlFor={id}>{label}</label>
            {type === 'select' &&
                <select value={value} className={classes} onChange={onChange} disabled={disabled}>
                    {data.map(d => <option value={d.value}>{d.text}</option>)}
                </select>
            }

            {type === 'textarea' &&
                <textarea className={classes} id={id} placeholder={placeholder}></textarea>
            }
            {type !== 'select' && type !== 'textarea' &&
                <input type={type} id={id} placeholder={placeholder} className={classes} />
            }

        </div>
    )
}

export default LabelledInputField;
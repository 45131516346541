import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import logo from "../../application/assets/images/jalsalogo.png";
import "./LoginLayout.css";

const LoginLayout = ({ children }) => {

    const location = useLocation();

    const test = () => {
        if (location.pathname === '/login/admin') return "Admin";
        else if (location.pathname === '/login/tajneed') return "Tajneed";
        else return "Member";
    }

    return (
        <div >
            <div className="login-component">
                <div className="login-component-containerOne">
                    <div className="md:flex md:flex-col md:items-start ">
                       
                      
                        
                    </div>
                </div>
                <div className="login-component-containerTwo ">
                    <div className="background-white">
                  
                        <div className=" w-full w-[80%] h-full flex items-center mt-10  flex-col gap-[3pc]">
                        <div className="flex flex-col items-center md:items-end md:flex md:flex-row">
                            <img src={logo} alt="img" className="h-[70px] w-[60px] md:h-[80px] md:w-[70px]" />
                            <span className="text my-5 text-lg">JALSA SALANA NIGERIA</span>
                        </div>
                            <div className="h-auto w-full flex-col">
                           
                                <div className="hidden md:block">
                                    <span className="text-2xl font-[700] tracking-[0.2rem] opacity-[49%]" >Login <span className="text-lg tracking-normal font-light"> - {test()}</span></span>
                                </div>
                                <div>
                                    {children}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LoginLayout;
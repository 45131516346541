import logo from "../../application/assets/images/jalsalogo.png";
import { FaUserCircle, FaUserPlus, FaCar, FaTags, FaRegBuilding, FaArrowAltCircleLeft, FaArrowAltCircleRight, FaHome, FaUsers, FaUserFriends } from 'react-icons/fa';
import { MdVolunteerActivism } from 'react-icons/md';
import { useState } from "react";
import './NavigationLayout.css'
import { useNavigate, useLocation, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedInUserDetails } from '../../application/store/actions/user';
import { setLoading } from '../../application/store/actions/ui';

const NavigationLayout = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const userAuthLevel = user.userAuthLevel;
    const isMemberActive = userAuthLevel == "member";
    const isNazimActive = userAuthLevel == "nazim volunteer";
    const isAdminActive = userAuthLevel == "national" || userAuthLevel == "circuit" || userAuthLevel == "jamaat" || userAuthLevel == "admin";
    const isTajneedActive = userAuthLevel == "admin";
    const [isLogoutPopActive, setIsLogoutPopActive] = useState(false);
    const activeSideOption = location.pathname.split("/")[1];

    const navOptions = [
        {
            displayText: "dashboard",
            action: "dashboard",
            isActive: isAdminActive,
            icon: FaHome,
        },
        {
            displayText: "register",
            action: "register",
            isActive: isMemberActive || isAdminActive,
            icon: FaUserPlus,
        },
        {
            displayText: "manifest",
            action: "manifest",
            isActive: isMemberActive || isAdminActive,
            icon: FaCar,
        },
        {
            displayText: "print tags",
            action: "printtag",
            isActive: isMemberActive || isAdminActive,
            icon: FaTags,
        },
        {
            displayText: "view guests",
            action: "register/guests",
            isActive: isMemberActive || isAdminActive,
            icon: FaUserFriends,
        },
        // {
        //     displayText: "print",
        //     action: "print",
        //     isActive: isMemberActive || isAdminActive,
        //     icon: FaTags,
        // },
        {
            displayText: "Users",
            action: "users",
            isActive: isTajneedActive,
            icon: FaUsers,
        },
        {
            displayText: "Department",
            action: "departments",
            isActive: isNazimActive,
            icon: FaRegBuilding,
        },
        {
            displayText: "Tags",
            action: "departments",
            isActive: isNazimActive,
            icon: FaRegBuilding,
        },
        {
            displayText: "Stats",
            action: "stats",
            isActive: true,
            icon: FaRegBuilding,
        },
        {
            displayText:  (isMemberActive) ?  "Volunteer" : "Volunteers",
            action: "volunteers",
            isActive: isMemberActive || isAdminActive || isNazimActive || isTajneedActive,
            icon: MdVolunteerActivism,
        },
    ]

    const changeActiveSideOption = (activeOption) => {
        navigate(`/${activeOption}`);
    }

    const logout = () => {
        dispatch(setLoggedInUserDetails({ userId: null, }));
        localStorage.removeItem('userDetails');
        localStorage.removeItem('userRoles');
        dispatch(setLoading(false));
        navigate('/');
    }
    return (
        <div className={`bg-theme-bgcolor h-[100vh] w-[100vw] flex flex-col `}>
            <div className=" md:mx-0 md:my-0 min-w-[300px] w-full flex h-full">
                <div className={`side-bar`}>
                    <div className="flex items-start h-[15%] w-full p-2 ">
                        <div className="flex-1 flex items-center">
                            <img src={logo} className="h-[25%] w-[30%]" />
                            <span className="side-heading" style={{ color: "white" }}>JALSA</span>

                        </div>
                    </div>
                    <div className="side-option-container">
                        <div className="side-option-mini-container ">
                            {
                                navOptions.map((n, index) =>
                                    n.isActive &&
                                    <div key={index} className={`side-options md:hidden ${activeSideOption == n.action ? `active border-2 border-theme-color` : ""}`} onClick={(e) => { changeActiveSideOption(n.action) }}>
                                        <n.icon size={20} className="mr-[2px]" />
                                        <span className="side-option-text">{n.displayText.toUpperCase()}</span>
                                    </div>
                                )
                            }
                        </div>

                        <div className="flex  items-center" onClick={logout}>
                            <FaArrowAltCircleLeft size={20} className="mr-3" />
                            <span className="side-option-text-sm">LOGOUT</span>
                        </div>

                    </div>
                </div>
                <div className="h-full w-[100%] md:w-[75%] block">
                    <div className="top-heading-container h-[10%]">
                        <div className="flex flex-1 items-center md:hidden ">
                            <img src={logo} className="h-[33px] w-[33px]" />
                            <span className="top-heading">JALSA</span>
                        </div>
                        <div className="name-container">
                            <span className="name">{`${user.userSurname.toUpperCase()} ${user.userFirstName.toUpperCase()}`}</span>

                            <FaUserCircle size={25} className="opacity-[49%]" onClick={() => setIsLogoutPopActive(!isLogoutPopActive)} />
                            <div className="pop-up-container">
                                <div onClick={logout} className={` ${isLogoutPopActive ? "pop-logout" : "hidden"}`}>

                                    <span className="pop-up-text">LOGOUT</span>
                                    <FaArrowAltCircleRight size={20} className="ml-2" />
                                </div>
                            </div>
                        </div>
                        <div className="breakline"></div>
                    </div>
                    <div className="h-[90%] ">
                        {children}
                    </div>
                </div>
            </div>
            <div className={`h-[8%] fixed bottom-0 z-[10]  w-full bg-white md:hidden`}>
                <div className="bottom-option-mini-container ">
                    {
                        navOptions.map((n, index) =>
                            n.isActive &&
                            <div key={index} className={`bottom-options ${activeSideOption == n.action ? `active` : ""}`} onClick={() => { changeActiveSideOption(n.action) }}>
                                <div className={`icon ${activeSideOption == n.action ? ` bg-theme-color` : ""}`} >
                                    <n.icon size={20} />
                                </div>
                                <span className="text" >{n.displayText.toUpperCase()}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>


    );
}

export default NavigationLayout;
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginForm from "../container/LoginForm";
import LoginLayout from "../layout/LoginLayout";
import services from "../../ioc/services";

const LoginPage = () => {
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("userDetails"))?.userId;
  const user = useSelector((state) => state.user);
  const userIdStore = user.userId;
  const userAuthLevel = user.userAuthLevel;
const loc = window.location.href.split("=");
console.log(loc, "location");

  useEffect(() => {
    if (userId !== undefined && userIdStore !== null) {
      if (
        userAuthLevel == "national" ||
        userAuthLevel == "circuit" ||
        userAuthLevel == "jamaat"
      )
        navigate("/dashboard");
      else if (userAuthLevel == "nazim volunteer") navigate("/departments");
      else navigate("/register");
    }
  }, [userId, userIdStore]);
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  );
};

export default LoginPage;
